<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">    
    <TheSidebar v-if="authUser"/>
    <!-- <TheAside/> -->
    <CWrapper>
      <TheHeader v-if="authUser"/>   
      <PublicTheHeader v-else/>   

      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <div v-if="authUser">
                <loading :active.sync="loading"
                  :can-cancel="false"
                  background-color="#fff"
                  :is-full-page="false"
                  v-if="loading">
                </loading>
                <router-view :key="$route.path" v-else></router-view>
              </div>
              <div v-else>
                <router-view :key="$route.path"></router-view>
              </div>
              
            </transition>
          </CContainer>
        </main>
      </div>         
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
// import nav from '@/_nav'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheAside from './TheAside'
import PublicTheHeader from './PublicTheHeader'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {userProfileMixin} from '../mixins/userProfile';

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside,
    PublicTheHeader,
    Loading,
  },
  mixins: [userProfileMixin],
  data(){
    return {
      // nav: nav.items,
      loading: true,
      authUser: false,
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  },
  created(){
    // console.log('CONTAINER')
    const currentUser = this.$firebase.auth().currentUser
    const userId = currentUser ? currentUser.uid : "";
    // console.log('Container User:', userId)
    if (userId){
      this.getUserProfile(userId) //
      this.authUser = true      
    }
    this.watchAuthUser();
  },
  methods: {
    visitSignUp: function(event){
        event.preventDefault() 
  this.$router.replace('/pages/register')
    },
    // visitExpert: function(){
    //   this.$router.replace({ path: '/pages/expert'})
    // },
    visitLogin: function(){
      this.$router.replace({ path: '/pages/login'})
    },
    visitMain: function(){
      this.$router.replace({ path: '/pages/main'})
    },
    watchAuthUser: function(){      
      this.$store.subscribe((mutation, state) => {        
        if (mutation.type === 'defUserId') {
          let userId = this.$store.getters.userId;          
          if (userId){            
            this.getUserProfile(userId)            
            this.authUser = true
          } else {
            this.authUser = false
          }
        }
      });
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
