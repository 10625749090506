export const userProfileMixin = {
    data(){
        return {
          loading: true,
          
          userProfile: null,
          firstName: null,
          phone_number: null,
          city: null,
          address: null,
          neighborhood: '',
          email:null,
          position: null,
          userId: null,
                    
          profileImage: null,
          profileImageUrl: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
          profileImageUrlThumb: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
          hasUserProfile: false,
          profilePresent: true,
        //   userCurrency: null,
          userPgId: null
        }
    },
    created(){
      if (this.$store.getters.userDbId){
        this.userPgId = this.$store.getters.userDbId
      };
    },
    methods: {
      getUserProfile: function(userId, updateProfile=true){
        // console.log('userProfile-GetUserProfile')        
        this.loading = true;
        this.$store.dispatch('fetchUser', {'userId': userId, 'updateProfile': updateProfile}).then((response) => {          
          let userProfile = response;
          if(updateProfile){            
            let userData = response;
            if (userData){     
              this.$store.commit('defUserDbId', userData.id);
              this.$store.commit('defUserProfile', JSON.stringify(userData));   
              this.$store.commit('defUserType', userData.type)
              // console.log(userProfile.pets)                         
            }            
            userProfile = userData;
            this.hasUserProfile = true;
          }
          if (userProfile){              
            this.userProfile = userProfile
            this.userPgId = userProfile.id;

            this.firstName = userProfile.nombre;
            this.phone_number = userProfile.celular;
            this.city =  userProfile.ciudad;
            this.neighborhood = userProfile.barrio;
            this.address = userProfile.direccion;
            this.email = userProfile.email;
            this.position = userProfile.ubicacion;
            if (userProfile.type == 'provider') {
              this.userId = userProfile.login_key;
            } else {
              this.userId = userProfile.user_id;
            }                                                                            
            this.hasUserProfile = true;                                   
          } else {
            console.log("don't have user profile")
            this.hasUserProfile = true;
            this.profilePresent = false
          }
        }).catch((e) => {
          console.log('error fetching profile: ', e)          
          this.hasUserProfile = false;
          this.profilePresent = false;                       
        }).finally(() => {
          this.loading = false          
        })
      },               
    }
};
