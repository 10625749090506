export const sidebarItemsAdminMixin = {
  name: "nav",
  computed: {
    sidebarItemsAdmin() {
      return [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavItem",
              name: this.$t("Operaciones"),
              to: "/admin",
              icon: "cil-home"
            },
            {
              _name: "CSidebarNavItem",
              name: "Pagos y gastos",
              to: "/financial",
              icon: "cil-dollar"
            }
          ]
        }
      ];
    }
  }
};
