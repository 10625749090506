<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
     <img src="../assets/images/logo.png" class="c-sidebar-brand-full" height="48px"/>
     <img src="../assets/images/logo.png" class="c-sidebar-brand-minimized" height="35px"/>
      <!-- <CIcon
        class="c-sidebar-brand-full"
        name="cilAnimal"
        size="2xl"
        :height="35"
        viewBox="0 0 642 134"      
        
      /> -->
      <!-- <CIcon
        class="c-sidebar-brand-minimized"
        name="cilAnimal"
        size="2xl"
        :height="35"
        viewBox="0 0 110 134"
      />     -->
    </CSidebarBrand>
        
    <CRenderFunction flat :contentToRender="sidebarItems" v-if="clientType=='client'"/>
    <CRenderFunction flat :contentToRender="sidebarItemsWalker" v-if="clientType=='provider'"/>
    <CRenderFunction flat :contentToRender="sidebarItemsAdmin" v-if="clientType=='admin'"/>

    <CButton 
      class="s-bg s-bg-text mr-1"
      style="width:100%;"
      @click="logout" 
      >
        Cerrar sesión      
    </CButton>
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import {sidebarItemsMixin} from '../mixins/SidebarItems'
import {sidebarItemsWalkerMixin} from '../mixins/SidebarItemsWalker'
import {sidebarItemsAdminMixin} from '../mixins/SidebarItemsAdmin'
import firebase from 'firebase';
export default {
  name: 'TheSidebar',
  mixins: [sidebarItemsWalkerMixin, sidebarItemsMixin, sidebarItemsAdminMixin],
  
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  },
  mounted () {
    const currentUser = firebase.auth().currentUser
    const userId = currentUser != null ? currentUser.uid : "";
    this.watchUserType();
  },
  data() {
    return {
      loading: true,
      clientType: null
    }
  },
  methods: {
    logout: async function() {
      try {
        await firebase.auth().signOut()
        this.$store.commit('defUserId', null)
        this.$store.commit('defUserProfile', null)
        this.$store.commit('defUserType', null)
        this.$router.replace('/pages/main?cityId=1')
      } catch (error) {
        console.log(error)
      }
    },
    watchUserType: function(){      
      this.$store.subscribe((mutation, state) => {           
        if (mutation.type === 'defUserType') {          
          let userType = this.$store.getters.userType;
          if (userType == 'client'){            
            this.clientType = 'client'  
          } 
          if (userType == 'provider') {
            this.clientType = 'provider'
          }
          if (userType == 'admin' || userType == 'owner') {
            this.clientType = 'admin'
          }
        }
      });
    }
  }
}
</script>

