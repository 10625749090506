<template>
    <div style="margin-bottom:-2rem;">
         <CNavbar
          expandable="md"
          class="p-bg"
          fixed="top"
        >
          <CToggler inNavbar @click="show=!show"/>
          <CNavbarBrand @click.prevent="visitMain">
              <img src="@/assets/images/logo.png" class="c-sidebar-brand-minimized" height="25px"/>   <!-- ancho de la primera barra azul estaba en 35 -->   
          </CNavbarBrand>
          <CCollapse :show="show" navbar>
            

            <!-- Right aligned nav items -->
            <CNavbarNav class="ml-auto">
              <CForm inline class="align-middle">   
                <b-button  
                  type="button"                   
                  class="btn btn-lg p-bg-light-text btn-first-margin" 
                  @click="visitLogin"
                  style="margin-right: 0.5rem"    
                  size="sm">
                    <i class="cui-people icons"></i>
                    <strong>Iniciar sesión</strong> 
                </b-button>                 
               
                <!-- <CButton                                         
                    class="p-bg-text"
                    size="sm" 
                    @click="visitLogin">
                    <strong>Iniciar sesión</strong>                  
                </CButton> -->
                
                <CButton 
                    class="s-bg s-bg-text"
                    size="sm" 
                    @click="visitSignUp"
                    style="margin-left:0.3rem;">
                    <strong>Regístrate</strong>                  
                </CButton>
              </CForm>
              </CNavbarNav>
          </CCollapse>
        </CNavbar>
  <!-- <b-navbar class="main-navbar navbar-light bg-light" toggleable="lg" fixed="top">
    <b-link class="navbar-brand" @click.prevent="visitMain">          
      <img src="@/assets/images/logo.png" class="c-sidebar-brand-minimized" height="35px"/>      
    </b-link>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
     
      <b-navbar-nav class="ml-auto">              
        <b-nav-item class="btn btn-link button-props" href="#" @click="visitLogin" role="button">Iniciar sesión</b-nav-item>
        <b-nav-item class="btn  button-props btn-first" href="#" @click="visitSignUp" role="button">
          <i class="cui-people icons text-white">&nbsp</i><strong class="text-white">Regístrate</strong>
        </b-nav-item>        
      </b-navbar-nav>
    </b-collapse>
  </b-navbar> -->
  </div>
</template>
<script>
import { Header as AppHeader } from '@coreui/vue'

export default {
  name: 'DefaultHeader',
  components: {
    AppHeader
  }, 
  data(){
      return {
          show: false,
      }
  },
  methods: {
    visitDashboard: function(){
      this.$router.go('/dashboard')
    }, 
    visitSignUp: function(event){
        event.preventDefault()
        this.$router.replace('/pages/register')
    }, 
    // visitExpert: function(){
    //   this.$router.replace({ path: '/pages/expert'})
    // },
    visitLogin: function(){
      this.$router.replace({ path: '/pages/login'})
    },
    visitMain: function(){
      this.$router.replace({ path: '/pages/main'})
    }
  }
}
</script>
<style scoped>
    .ml-auto{
        padding: 1px;
    }

    .color{
      background-color: #ebedef;
    }

    .nav-link-custom, .discover-link{
      margin-right: 1rem;
      font-weight: 800;
      border-bottom: 2px ;
    }

    .nav-link-custom:hover, .discover-link:hover{
      font-size: 1rem;
      border-bottom: 3px;
    }

    .main-public{
      margin: 0 !important;
    }
    
    .link-centered{
      text-align: center; 
    }

    .button-props{
      padding: 0;         
    }

</style>
